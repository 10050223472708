export const DECLARATION_CONTENT = [
    {
        id: 1,
        mainTitle: 'Deklaracja dostępności',
        isInternal: false,
        content: [
            {
                id: 1,
                text: '<span id="a11y-podmiot">Placówka Opiekuńczo-Wychowawcza “Ignaś” w Kaliskach i Placówka Opiekuńczo-Wychowawcza “Helenka” w Kaliskach</span> zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Deklaracja dostępności dotyczy strony internetowej <a href="https://powkaliska.pl" id="a11y-url" taget=_blank>https://powkaliska.pl</a>. Data publikacji strony internetowej: <time id="a11y-data-publikacja">2022-10-28</time>. Data ostatniej dużej aktualizacji:  <time id="a11y-data-aktualizacja">2022-10-28</time>.',
                isInnerHtml: true,
                type: 'text',
                a11yId: 'a11y-wstep',
            },
        ],
    },
    {
        id: 2,
        mainTitle: 'Stan dostępności cyfrowej',
        isInternal: true,
        content: [
            {
                id: 1,
                text: 'Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej.',
                isInnerHtml: false,
                type: 'text',
                a11yId: 'a11y-status',
            },
            {
                id: 2,
                listTitleText: 'Niedostępne elementy i treści:',
                listItems: [
                    {
                        id: 1,
                        text: 'Niektóre kolory nie mają wystarczającego stosunku kontrastu',
                    },
                    {
                        id: 2,
                        text: 'Niektóre nagłówki nie są w kolejności malejącej',
                    },
                    {
                        id: 3,
                        text: 'Niektóre linki nie mają rozpoznawalnej nazwy',
                    },
                ],
                type: 'list',
            },
        ],
    },
    {
        id: 3,
        mainTitle: 'Przygotowanie deklaracji dostępności',
        isInternal: true,
        content: [
            {
                id: 1,
                text: 'Data sporządzenia deklaracji: <time id="a11y-data-sporzadzenie">2023-02-27</time>.',
                isInnerHtml: true,
            },
            {
                id: 2,
                text: 'Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot wykonujący stronę internetową.',
                isInnerHtml: false,
            },
            {
                id: 3,
                text: 'Data ostatniego przeglądu deklaracji: <time id="a11y-data-przeglad">2023-02-27</time>',
                isInnerHtml: true,
            },
        ],
    },
    {
        id: 4,
        mainTitle: 'Skróty klawiaturowe',
        isInternal: true,
        content: [
            {
                id: 1,
                text: 'Na tej stronie internetowej można korzystać ze standardowych skrótów klawiaturowych.',
                isInnerHtml: false,
            },
        ],
    },
    {
        id: 5,
        mainTitle: 'Informacje zwrotne i dane kontaktowe',
        isInternal: true,
        mainTitleA11yId: 'a11y-kontakt',
        content: [
            {
                id: 1,
                text: 'Na tej stronie internetowej są problemy, które wynikają z niespełnienia wymogów dostępności cyfrowej? Zgłoś to do dyrektora placówki, <span id="a11y-osoba">Agaty Kuczyńskiej</span>, mailowo - <a href="mailto:pow-ignas@powiatwegrowski.pl" id="a11y-email">pow-ignas@powiatwegrowski.pl</a> lub telefonicznie - <a href="tel:256751137" id="a11y-telefon">25 675 11 37</a>.',
                isInnerHtml: true,
                type: 'text',
            },
            {
                id: 2,
                listTitleText: 'W zgłoszeniu podaj:',
                listItems: [
                    {
                        id: 1,
                        text: 'swoje imię i nazwisko',
                    },
                    {
                        id: 2,
                        text: 'swoje dane kontaktowe (np. numer telefonu, e-mail)',
                    },
                    {
                        id: 3,
                        text: 'dokładny adres strony internetowej, na której jest niedostępny element lub treść',
                    },
                    {
                        id: 4,
                        text: 'opis na czym polega problem i jaki sposób jego rozwiązania byłby dla Ciebie najwygodniejszy',
                    },
                ],
                type: 'list',
            },
            {
                id: 3,
                text: '<span class="highlighted">Obsługa wniosków i skarg związanych z dostępnością</span>',
                isInnerHtml: true,
                type: 'text',
                a11yId: 'a11y-procedura'
            },
            {
                id: 4,
                text: 'Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później niż w ciągu 14 dni od jego otrzymania.',
                isInnerHtml: false,
                type: 'text',
            },
            {
                id: 5,
                text: 'Jeżeli ten termin będzie dla nas zbyt krótki poinformujemy Cię o tym. W tej informacji podamy nowy termin, do którego poprawimy zgłoszone przez Ciebie błędy lub przygotujemy informacje w alternatywny sposób. Ten nowy termin nie będzie dłuższy niż 2 miesiące.',
                isInnerHtml: false,
                type: 'text',
            },
            {
                id: 6,
                text: 'Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony internetowej lub treści z Twojego zgłoszenia, zaproponujemy Ci dostęp do nich w alternatywny sposób.',
                isInnerHtml: false,
                type: 'text',
            },
            {
                id: 7,
                text: 'Jeżeli nadal będziesz mieć uwagi do naszych działań, związane z dostępnością cyfrową możesz zgłosić je do <a href="https://bip.brpo.gov.pl/pl/content/interwencje-u-wladz-w-pojedynczych-sprawach" target="_blank">Rzecznika Praw Obywatelskich</a>.',
                isInnerHtml: true,
                type: 'text',
            },
        ],
    },
    {
        id: 6,
        mainTitle: 'Dostępność architektoniczna',
        isInternal: true,
        mainTitleA11yId: 'a11y-architektura',
        content: [
            {
                id: 1,
                text: 'Budynki Placówki Opiekuńczo-Wychowawczej “Ignaś” w Kaliskach i Placówki Opiekuńczo-Wychowawczej “Helenka” w Kaliskach znajdują się na ogrodzonym terenie. Do budynków prowadzi utwardzony podjazd.',
                isInnerHtml: false,
            },
            {
                id: 2,
                text: 'Nie ma wyznaczonych miejsc parkingowych dla osób niepełnosprawnych z uwagi na dużą dostępność wolnych miejsc parkingowych na terenie placówek.',
                isInnerHtml: false,
            },
            {
                id: 3,
                text: 'Budynek Placówki Opiekuńczo-Wychowawczej “Ignaś” w Kaliskach',
                isInnerHtml: false,
                isTitle: true,
            },
            {
                id: 4,
                text: 'Budynek Palcówki “Ignaś” jest usytuowany jako pierwszy poruszając się w głąb posesji. Do budynku prowadzi jedno wejście opatrzone napisem “IGNAŚ”. Wejście znajduje się na poziomie gruntu i nie jest zabezpieczone bramkami. Nad wejściem nie ma głośników systemu naprowadzającego dźwiękowo osoby niewidome. W budynku znajduje się toaleta przystosowana dla osób niepełnosprawnych, poruszających sie na wózku inwalidzkim. Mieści się ona na końcu lewej części korytarza.',
                isInnerHtml: false,
            },
            {
                id: 5,
                text: 'W budynku nie ma windy. Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem. W budynku nie ma pętli indukcyjnych. W budynku nie ma oznaczeń w alfabecie brajla, ani oznaczeń kontrastowych lub w druku powiększonym dla osób słabowidzących.',
                isInnerHtml: false,
            },
            {
                id: 6,
                text: 'Jednostka nie posiada tłumacza polskiego języka migowego.',
                isInnerHtml: false,
            },
            {
                id: 7,
                text: 'Budynek Placówki Opiekuńczo-Wychowawczej “Helenka” w Kaliskach',
                isInnerHtml: false,
                isTitle: true,
            },
            {
                id: 8,
                text: 'Budynek Palcówki “Helenka” jest usytuowany jako drugi poruszając się w głąb posesji. Do budynku prowadzi jedno wejście opatrzone napisem “HELENKA”. Wejście znajduje się na poziomie gruntu i nie jest zabezpieczone bramkami. Nad wejściem nie ma głośników systemu naprowadzającego dźwiękowo osoby niewidome. W budynku znajduje się toaleta przystosowana dla osób niepełnosprawnych, poruszających sie na wózku inwalidzkim. Mieści się ona na końcu lewej części korytarza.',
                isInnerHtml: false,
            },
            {
                id: 9,
                text: 'W budynku nie ma windy. Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem. W budynku nie ma pętli indukcyjnych. W budynku nie ma oznaczeń w alfabecie brajla, ani oznaczeń kontrastowych lub w druku powiększonym dla osób słabowidzących.',
                isInnerHtml: false,
            },
            {
                id: 10,
                text: 'Jednostka nie posiada tłumacza polskiego języka migowego.',
                isInnerHtml: false,
            },
        ],
    },
];
