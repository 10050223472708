import React from 'react';

import PageLayout from '../components/layout/PageLayout';
import { TextSection } from '../components/sections';
import Seo from '../components/seo';

import { DECLARATION_CONTENT } from '../content/declaration_of_availability';

const DeclarationOfAvailabilityPage = ({ location }) => {
    return (
        <>
            <Seo 
                title='Deklaracja dostępności | P.O.W. Ignaś i Helenka w Kaliskach'
                description='Deklaracja dostępności'
            />

            <PageLayout location={ location }>
                <TextSection config={ DECLARATION_CONTENT } />
            </PageLayout>
        </>
    )
};

export default DeclarationOfAvailabilityPage;
